import React, { useContext } from "react";
import { FormattedDate, FormattedMessage, IntlProvider, useIntl } from "react-intl";
import { formatDateDDMMYYYY, getNextDate } from "../../common/util";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { LocaleContext } from "../../global_contexts";
import { numberFormat } from "../utility/currency_format";

export const calculateDate = (date) => {
  const d = date && formatDateDDMMYYYY(date);
  return d ? d : "-";
};

export const calculateFee = (INTL, fee, vat) => {
  const man_charge = parseFloat(fee);
  const incl_vat =
    +vat !== 0
      ? INTL.formatMessage({
        id: "profile.key_terms.incl_vat",
        defaultMessage: "(incl. of VAT) of Net Booking Value",
      })
      : "";
  return isNaN(man_charge) ? " - " : `${man_charge}% ${incl_vat}`;
};

const feeComponent = (INTL, chargeBlock, vat, regular_fee) => {
  const noChargeblockFee = calculateFee(INTL, regular_fee, vat);
  if (chargeBlock) {
    const untilDate = calculateDate(chargeBlock.end_date);
    const fromDate = formatDateDDMMYYYY(
      getNextDate(1, false, chargeBlock.end_date).toISOString()
    );
    return (
      <td>
        <p className="mt-0 mb-0">
          {calculateFee(INTL, chargeBlock.management_charge, vat)}{" "}
          {INTL.formatMessage({
            id: "profile.key_terms.discounted_until",
            defaultMessage: "discounted until",
          })}{" "}
          {untilDate}
        </p>
        <p className="mb-0">
          {noChargeblockFee}{" "}
          {INTL.formatMessage({ id: "profile.key_terms.from", defaultMessage: "from" })}{" "}
          {fromDate}
        </p>
      </td>
    );
  } else {
    return <td>{noChargeblockFee}</td>;
  }
};

// Function to safely replace newline characters with <br> tags
export const formatRichText = (text) => {
  if (!text) {
    return { __html: "" };
  }
  return { __html: text.replace(/\r\n/g, "<br>") };
};

const HostKeyTerms = (prop) => {
  const INTL = useIntl();
  const { locale } = useContext(LocaleContext);

  return (
    <table className={`table-box striped ${(prop.single || prop.inTC) && "one"}`}>
      <thead className="thead-box">
        <tr>
          <th colSpan="2">
            <div className="icon-agreement valign-wrapper">
              <Icon
                icon={ICONS.AGREEMENT.d}
                width={75}
                height={38}
                viewBox="50 18 165 80"
                color="#fff"
              />
              <span>{prop.property.property_name}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <FormattedMessage
              id="profile.key_terms.services"
              defaultMessage="Services"
            />
          </td>
          <td>
            <FormattedMessage
              id="profile.key_terms.property_management_services"
              defaultMessage="Property management services for your Short-Let Properties as set out in more detail in the Terms."
            />
          </td>
        </tr>
        <tr>
          <td className={prop.property.management_charge_block && "top"}>
            <FormattedMessage
              id="profile.service_details.management_fee"
              defaultMessage="Management Fee"
            />
          </td>
          {feeComponent(
            INTL,
            prop.property.management_charge_block,
            prop.property.vat_amount,
            prop.property.management_charge
          )}
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="profile.key_terms.onboarding_fee"
              defaultMessage="Onboarding Fee"
            />
          </td>
          <td>
            {numberFormat(
              locale,
              prop.property.onboarding_fee_paid,
              localStorage.getItem("currency"),
              0
            )}
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="profile.service_details.service_provider"
              defaultMessage="Service Provider"
            />
          </td>
          <td className="detailed_list">
            <div>
              <div className="no-border">
                <FormattedMessage
                  id="profile.service_details.service_provider.company_name"
                  defaultMessage="Company Name"
                />
              </div>
              <div>{prop.property.service_provider}</div>
            </div>
            <div>
              <div className="no-border">
                <FormattedMessage
                  id="profile.service_details.service_provider.company_number"
                  defaultMessage="Company Number"
                />
              </div>
              <div>{prop.property.service_provider_company_number}</div>
            </div>
            <div className="last_row">
              <div className="no-border">
                <FormattedMessage
                  id="profile.service_details.service_provider.registered_address"
                  defaultMessage="Registered Address"
                />
              </div>
              <div>{prop.property.service_provider_registered_address}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="profile.key_terms.cancelation_rights"
              defaultMessage="Cancellation Rights"
            />
          </td>
          <td>
            <FormattedMessage
              id="profile.key_terms.cancelation_rights.text"
              defaultMessage="14-day cooling off period"
            />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="profile.key_terms.effective_date"
              defaultMessage="Effective Date"
            />
          </td>
          <td>
            {/* IntlProvder used to ensure British locale rather than default US locale */}
            <IntlProvider locale={locale === "en" ? "en-GB" : locale}>
              <FormattedDate
                value={prop.property.effective_date}
                year="numeric"
                month="long"
                day="numeric"
              />
            </IntlProvider>
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="profile.key_terms.minimum_term"
              defaultMessage="Minimum Term"
            />
          </td>
          <td>
            <FormattedMessage
              id="profile.key_terms.minimum_term.text_one"
              defaultMessage="An initial period of"
            />{" "}
            <b>{prop.property.minimum_term_period}</b>{" "}
            <FormattedMessage
              id="profile.key_terms.minimum_term.text_two"
              defaultMessage="calendar days commencing on the Effective Date, during which you will provide no less than"
            />{" "}
            <b>{prop.property.minimum_available_days}</b>{" "}
            <FormattedMessage
              id="profile.key_terms.minimum_term.text_three"
              defaultMessage="calendar days' worth of Available Days."
            />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="profile.key_terms.notice_period"
              defaultMessage="Notice Period"
            />
          </td>
          <td>
            {prop.property.notice_period}{" "}
            <FormattedMessage id="profile.key_terms.days" defaultMessage="days" />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage id="profile.key_terms.term" defaultMessage="Term" />
          </td>
          <td>
            <FormattedMessage
              id="profile.key_terms.term.text"
              defaultMessage="The period commencing on the Effective Date and continuing in force for the Minimum Term and thereafter until the end of the Notice Period, unless terminated earlier by either party in accordance with the terms of this Agreement."
            />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="profile.key_terms.other_particulars"
              defaultMessage="Other Particulars"
            />
          </td>
          <td
            dangerouslySetInnerHTML={formatRichText(
              prop.property.additional_particulars
            )}></td>
        </tr>
      </tbody>
    </table>
  );
};

export default HostKeyTerms;
